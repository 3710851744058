import React from "react";

export default function UnderContruction() {
  return (
    <div>
      <h1>Deze pagina is (nog) onder constructie.</h1>
      <img
        height="500px"
        src="https://www.elektormagazine.com/assets/upload/images/8/20190629113654_Elektor-Under-Construction.png"
        alt=""
      ></img>
    </div>
  );
}
