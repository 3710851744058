export const Nestje1Webp = require("./nestje1.webp");
export const Nestje2Webp = require("./nestje2.webp");
export const Nestje3Webp = require("./nestje3.webp");
export const Nestje4Webp = require("./nestje4.webp");
export const Nestje5Webp = require("./nestje5.webp");
export const Nestje6Webp = require("./nestje6.webp");
export const Nestje7Webp = require("./nestje7.webp");
export const Toppertje13Webp = require("./toppertje13.webp");
export const Dekentje14Webp = require("./dekentje14.webp");

export const Nestje1Jpg = require("./nestje1.jpg");
export const Nestje2Jpg = require("./nestje2.jpg");
export const Nestje3Jpg = require("./nestje3.jpg");
export const Nestje4Jpg = require("./nestje4.jpg");
export const Nestje5Jpg = require("./nestje5.jpg");
export const Nestje6Jpg = require("./nestje6.jpg");
export const Nestje7Jpg = require("./nestje7.jpg");
export const Toppertje13Jpg = require("./toppertje13.jpg");
export const Dekentje14Jpg = require("./dekentje14.jpg");

export const logoSuusjePluusjePng = require("./logoSuusjePluusje.png");
export const logoSuusjePluusjeWebp = require("./logoSuusjePluusje.webp");
